/* REACT PACKAGE IMPORTS */
import { useRef, useState, useEffect, useMemo } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

/* INTERNAL IMPORTS */
import {
  userLoaded,
  userLogged,
  getAuthStatus,
  authError,
  hideError,
} from "../Features/Auth/Slices/authSlice";
import { LoginAuthAction } from "../Features/Auth/Actions/AuthActions";
import { openModal } from "../Features/Errors/Slices/errorSlice";

import "../styles/Login.css";


export default function AdminLogin({ logo }) {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const navigate = useNavigate();

  const { status } = useSelector(state => state.auth);
  const isError = useSelector(getAuthStatus) === "failed" ? true : false;
  const message = useSelector(authError);
  const isLoaded = useSelector(userLoaded);
  const user = useSelector(userLogged);

  const [validated, setValidated] = useState(false);
  const [isShowed, setIsShowed] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isValidUsername, setIsValidUsername] = useState(null);
  const [isValidPassword, setIsValidPassword] = useState(null);

  const mounted = useRef(null);
  const userRef = useRef();
  const passRef = useRef();

  useEffect(() => {
    document.title = "VX";
    userRef.current.focus();
  }, []);

  useEffect(() => dispatch(hideError()), [username, password, dispatch]);

  useEffect(() => {
    if (message) {
      dispatch(
        openModal({
          message: message,
          severity: "warning"
        })
      );
    }
  }, [isError]);

  useEffect(() => {
    mounted.current = true;
    const queryUser = query.get("username");
    const queryPassword = query.get("password");

    if (!queryUser || !queryPassword) return;
    if (mounted.current) {
      setUsername(queryUser);
      setPassword(queryPassword);
      const credentials = {
        username: queryUser,
        password: queryPassword,
      };
      dispatch(LoginAuthAction(credentials))
        .unwrap()
        .then(response => {
          //if (!response.error) document.body.requestFullscreen();
        })
        .catch(() => navigate("/admin/unauthorized", { replace: true }));
    }
    return () => mounted.current = false;
  }, [query]);

  useEffect(() => {
    mounted.current = true;

    if (!isLoaded) return;
    if (mounted.current && status === "succeeded") {
      if (user?.roles.includes(1)) {
        navigate("/admin", { replace: true });
      } else if (user?.roles.includes(3)) {
        navigate("/candidate", { replace: true });
        dispatch(
          openModal({
            message: `${username} is not linked to a VICTVS Admin Account, logged in as a candidate` ,
            severity: "info"
          })
        );
        setValidated(false);
      }
    }
    return () => mounted.current = false;
  }, [isLoaded, user]);

  const validateUsernameAndPassword = () => {
    // Case: Password and Username fields Blank
    if (username === "" && password === "") {
      dispatch(
        openModal({
          message: `Fields cannot be left empty`,
          severity: "warning"
        })
      );
      setIsValidUsername("false");
      setIsValidPassword("false");
      userRef.current.focus();
      return false;
    } 
    // Case: Username field blank
    else if (username === "") {
      dispatch(
        openModal({
          message: `Username cannot be left empty`,
          severity: "warning"
        })
      );
      setIsValidUsername("false");
      setValidated(false);
      userRef.current.focus();
      return false;
    }
    // Case: Password field blank
    else if (password === "") {
      dispatch(
        openModal({
          message: `Password cannot be left empty`,
          severity: "warning"
        })
      );
      setIsValidPassword("false");
      setValidated(false);
      passRef.current.focus();
      return false;
    } 
    // Case: Happy path, no validation errors
    else {
      setIsValidUsername("true");
      setIsValidPassword("true");
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const credentials = {
      username: username,
      password: password,
    };

    if (validateUsernameAndPassword()) {
      // document.body.requestFullscreen()
      dispatch(LoginAuthAction(credentials))
        // .then(response => {
        //   if (!response.error) document.body.requestFullscreen();
        // });
    } else event.stopPropagation();
    
  };

  const handleUsernameChange = (event) => {
    if (isValidUsername) setIsValidUsername(null)
    setUsername(event.target.value)
  };

  const handlePasswordChange = (event) => {
    if (isValidPassword) setIsValidPassword(null)
    setPassword(event.target.value)
  };


  return (
    <main className="Login position-relative">
      <div className="Login-container box position-absolute top-50 start-50 translate-middle p-4">
        <div className="d-flex flex-column mx-5 my-2 justify-content-between align-items-center">
          {/* Invisible h1 added for accessibility requirements */}
          <h1 className="visually-hidden">victvs vx</h1>

            <img src={logo} alt="Logo" className="h-32"/>

          <p className="my-2 text-slate-600">Sign In to your Admin Account</p>
        </div>
        <Form
          id="form"
          className="mx-5 my-4 p-2"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-4">
            <Form.Label id="login-username">Username</Form.Label>
            <Form.Control
              data-cy="usernameField"
              aria-labelledby="login-username"
              title="username"
              name="username"
              type="text"
              ref={userRef}
              value={username}
              onChange={event => handleUsernameChange(event)}
              isInvalid={isValidUsername === "false"}
              isValid={isValidUsername === "true"}
              required
            />
            <Form.Text>
              <Link
                className="d-block text-end text-decoration-none mt-1 link-font-size"
                to="/forgot-username"
              >
                Forgot Username?
              </Link>
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label id="login-password">Password</Form.Label>
            <InputGroup>
              <Form.Control
                data-cy="passwordField"
                aria-labelledby="login-password"
                title="password"
                type={!isShowed ? "password" : "text"}
                name="password"
                value={password}
                onChange={event => handlePasswordChange(event)}
                ref={passRef}
                isInvalid={isValidPassword === "false"}
                isValid={isValidPassword === "true"}
                required
              />
              <InputGroup.Text className="rounded-end">
                <i onClick={() => setIsShowed(!isShowed)} className="hover-pointer">
                  {
                    !isShowed ?
                      <VisibilityIcon fontSize="small" /> :
                      <VisibilityOffIcon fontSize="small" />
                  }
                </i>
              </InputGroup.Text>
            </InputGroup>
            <Form.Text>
              <Link
                className="d-block text-end text-decoration-none mt-1 link-font-size"
                to="/forgot-password"
              >
                Forgot Password?
              </Link>
            </Form.Text>
          </Form.Group>

          <div className="my-4">
            <Button
              data-cy="signInButton"
              variant="dark"
              type="submit"
              className="mt-3 container"
            >
              Sign in
            </Button>
          </div>
          <div className="d-flex flex-column">
            <Form.Text>
              Have a Candidate Account?
              <Link
                className="mx-2 text-decoration-none link-font-size"
                to="/login"
              >
                Candidate Sign In
              </Link>
            </Form.Text>
          </div>
        </Form>
      </div>
    </main>
  );
}
