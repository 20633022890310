import { getURL, setHeaders } from "../../../Apis/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openModal } from "../../Errors/Slices/errorSlice";

const RegisterAuthAction = createAsyncThunk(
  "auth/register",
  async (registrationDetails, thunkAPI) => {
    try {
      const requestBody = {
        username: registrationDetails.username,
        email: registrationDetails.email,
        password: registrationDetails.password,
        firstname: registrationDetails.firstname,
        lastname: registrationDetails.lastname,
        title: registrationDetails.title,
        country: registrationDetails.country,
        region: registrationDetails.region,
        locality: registrationDetails.locality,
        address1: registrationDetails.addressOne,
        address2: registrationDetails.addressTwo,
        postcode: registrationDetails.postcode,
        mpn: registrationDetails.mobileNumber,
        telephone: registrationDetails.telephone,
        orgid: 3
      };
      const Axios = getURL();
      const response = await Axios.post("/users", requestBody);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const verifyEmail = createAsyncThunk(
  "auth/verifyemail",
  async(emailAddress, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.get(`/users?email=${emailAddress}`);
      return response.data.data.status;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const verifyUsername = createAsyncThunk(
  "auth/verifyusername",
  async(username, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.get(`/users?username=${username}`);
      return response.data.data.status;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
)

const getCountries = createAsyncThunk(
  "misc/getcountries",
  async(thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.get(`countries`);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const LoginAuthAction = createAsyncThunk(
  "auth/login",
  async (loginState, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.post("/logins", loginState);
      const accessToken = response?.data?.sesstoken;
      sessionStorage.setItem("auth", accessToken);
      return thunkAPI.dispatch(getUser());
    } catch (error) {
      return thunkAPI.rejectWithValue("Wrong username and password combination");
    }
  }
);

const LoginWithAuthToken = createAsyncThunk(
  "auth/loginAuthToken",
  async (data, thunkAPI) => {
    try {
      const accessToken = data.AUTH_TOKEN;
      const role = data.ROLE || null;
      sessionStorage.setItem("auth", accessToken);
      sessionStorage.setItem("role", role);
      console.log(data, "this is the postmessage data that is handled");
      return thunkAPI.dispatch(getAuthUser(role));
    } catch (error) {
      return thunkAPI.rejectWithValue("Wrong username and password combination");
    }
  }
);

const forgotPassword = createAsyncThunk(
  "/forgotpassword",
  async (data, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.post("/forgotpassword", data);
      
      thunkAPI.dispatch(
        openModal({
          message: `Password reset successfully sent to Email. Redirecting to the Sign In page.`,
          severity: "success",
        })
      )

    } catch (error) {
      if (error.response.status === 404 || error.response.status === 422) {
        thunkAPI.dispatch(
          openModal({
            message: "Email or Username not registered with VICTVS",
            severity: "error",
          })
        )
      }
      return thunkAPI.rejectWithValue("Forgot password rejected")
    }
  }
);

const forgotUsername = createAsyncThunk(
  "/forgotusername",
  async (data, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.post("/forgotusername", data);
      thunkAPI.dispatch(
        openModal({
          message: `Username successfully sent to Email. Redirecting to the Sign In page.`,
          severity: "success",
        })
      );

    } catch (error) {
      if (error.response.status === 404 || error.response.status === 422 ) {
        thunkAPI.dispatch(
          openModal({
            message: "Email not registered with VICTVS",
            severity: "error",
          })
        )
      }
      return thunkAPI.rejectWithValue("Forgot password rejected")
    }
  }
);

const getUser = createAsyncThunk(
  "auth/getUser",
  async (thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.get("/logins", setHeaders());
      const { data } = response;
      const { service } = data;
      const roles =
        service === "vxadmin" || data?.data?.backenduser_url ? [1] : [3];
      const userid =
        service === "vx" ? data?.data?.userid : data?.data?.loggedin_user?.userid;
      const fullname =
        service === "vx"
          ? `${data?.data?.firstname} ${data?.data?.lastname}`
          : `${data?.data?.loggedin_user?.firstname} ${data?.data?.loggedin_user?.lastname}`;
      const username =
        service === "vx"
          ? data?.data?.displayname
          : data?.data?.loggedin_user?.displayname;
      const theme =
        service === "vx" ? data?.data?.theme : data?.data?.loggedin_user?.theme;
      const organisation = await Axios.get(
        `/logins/${userid}/organisation`,
        setHeaders()
      );
      const { orgid }  = organisation.data.data;

      const user = { username, fullname, userid, roles, theme, orgid };
      console.log(user, "this is the user");
      sessionStorage.setItem("user", JSON.stringify(user));
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const getAuthUser = createAsyncThunk(
  "auth/getAuthUser",
  async (role, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.get("/logins", setHeaders());
      const { data } = response;
      const { service } = data;
      const roles = role === "admin" ? [1] : [3];
      const userid =
        service === "vx" ? data?.data?.userid : data?.data?.loggedin_user?.userid;
      const fullname =
        service === "vx"
          ? `${data?.data?.firstname} ${data?.data?.lastname}`
          : `${data?.data?.loggedin_user?.firstname} ${data?.data?.loggedin_user?.lastname}`;
      const username =
        service === "vx"
          ? data?.data?.displayname
          : data?.data?.loggedin_user?.displayname;
      const theme =
        service === "vx" ? data?.data?.theme : data?.data?.loggedin_user?.theme;
      const organisation = await Axios.get(
        `/logins/${userid}/organisation`,
        setHeaders()
      );
      const { orgid } = organisation.data.data;

      const user = { username, fullname, userid, roles, theme, orgid };
      console.log(user, "this is the user");
      sessionStorage.setItem("user", JSON.stringify(user));
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  },
);

const getUserAudit = createAsyncThunk(
  "auth/getUserAudit",
  async (userid, thunkAPI) => {
    try {
      const Axios = getURL();
      const audit = await Axios.get(`/users/${userid}/changes`, setHeaders());
      return audit.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const LogOutAuthAction = createAsyncThunk(
  "auth/logout",
  async thunkAPI => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?.userid;
    try {
      const Axios = getURL();
      const response = await Axios.delete(`/logins/${userId}`, setHeaders());
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("auth");
      sessionStorage.removeItem("session");
      sessionStorage.clear();
      const { data } = response;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchOrganisationsByUser = createAsyncThunk(
  "auth/fetchOrganisationsByUser",
  async (thunkAPI) => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?.userid;
    try {
      const Axios = getURL();
      const organisations = await Axios.get(
        `/logins/${userId}/organisations`,
        setHeaders()
      );
      return organisations.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchOrganisationByUser = createAsyncThunk(
  "auth/fetchOrganisationByUser",
  async (name, thunkAPI) => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?.userid;
    try {
      const Axios = getURL();
      const organisation = await Axios.get(
        `/logins/${userId}/organisation`,
        setHeaders()
      );
      return organisation.data.data.orgid;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const setOrganisationByUser = createAsyncThunk(
  "auth/setOrganisationByUser",
  async (orgid, thunkAPI) => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?.userid;
    try {
      const Axios = getURL();
      const organisations = await Axios.post(
        `/logins/${userId}/organisations`,
        { orgid },
        setHeaders()
      );
      thunkAPI.dispatch(
        openModal({
          message: "Your organisation has been updated",
          severity: "success",
        })
      );
      return orgid;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);


export {
  RegisterAuthAction,
  verifyEmail,
  verifyUsername,
  LoginAuthAction,
  LoginWithAuthToken,
  LogOutAuthAction,
  getAuthUser,
  getUser,
  getUserAudit,
  fetchOrganisationsByUser,
  fetchOrganisationByUser,
  setOrganisationByUser,
  forgotPassword,
  forgotUsername,
  getCountries
};
